<template>
  <v-dialog
    v-model="show"
    :max-width="$vuetify.breakpoint.smAndDown ? '600px' : '800px'"
    :max-height="$vuetify.breakpoint.smAndDown ? '600px' : '800px'"
    class="rounded"
  >
    <ImageWithDescription
      :readonly="readonly"
      :value="file"
      :aspectRatio="undefined"
      :contain="true"
      :hideDownloadButtons="true"
      :hideEditButtons="true"
      @delete="onDeleteClicked"
      @deleteFile="onDeleteFile"
      skipIntersection
    ></ImageWithDescription>
  </v-dialog>
</template>

<script>

export default {
  props: {
    value: {},
    file: {},
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    }
  },
  components: {
    ImageWithDescription: () => import("../components/ImageWithDescription")
  },
  methods: {
    onDeleteClicked() {
      this.$emit("delete");
      this.show = false;
    },
    onDeleteFile() {
      this.$emit("deleteFile");
      this.show = false;
    }
  }
};
</script>

<style scoped class="css">
.rounded {
  border-radius: 8px !important;
}
</style>
